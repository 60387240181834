<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <div>
    <div
      dragag
      @dragenter.prevent
      @dragleave.prevent
      @dragover.prevent
      @drop.prevent="
        showThumbnailOnSelect($event)
        upload($event)
      "
    >
      <input
        v-show="false"
        id="fileUploadInput"
        ref="fileUploadInputElement"
        type="file"
        :accept="acceptedFileTypes.join(',')"
        @change="
          showThumbnailOnSelect($event)
          upload($event)
        "
      />
      <div
        v-if="!isMobileDevice && !fileUploaded"
        :class="['upload-photo', { 'upload-failed': isFailedFileUpload }]"
      >
        <label for="fileUploadInput" class="upload-button">
          <AppIcon :name="$t('ComponentXeFileUpload.UploadPhotoText').value">
            <IconAddFile />
          </AppIcon>
          <p class="drag-drop-text" v-html="$t('ComponentXeFileUpload.DragAndDropText').value"></p>
          <p class="limit-size-text">{{ $t('ComponentXeFileUpload.FileExceedText').value }}</p>
        </label>
      </div>
      <div v-if="!isMobileDevice && isFailedFileUpload" class="error-text-desktop">
        {{ uploadErrorMsg }}
      </div>
      <div
        v-if="isMobileDevice && !fileUploaded && !isFailedFileUpload"
        class="upload-photo-mobile"
      >
        <label for="fileUploadInput" class="upload-button-mobile">
          <p>{{ $t('ComponentXeFileUpload.UploadPhotoText').value }}</p>
          <AppIcon :name="$t('ComponentXeFileUpload.UploadPhotoText').value">
            <IconImage />
          </AppIcon>
        </label>
      </div>
    </div>
    <div v-if="fileUploaded || (isFailedFileUpload && isMobileDevice)">
      <AppCard :class="isFailedFileUpload ? 'upload-failed' : 'upload-successful'">
        <div class="file-preview">
          <img
            v-if="previewThumbnailUrl"
            :src="previewThumbnailUrl"
            alt="Image preview thumbnail"
            class="thumbnail-image"
          />
          <IconDocumentPDF
            v-else-if="!isFailedFileUpload && file.type === 'application/pdf'"
            class="h-16 w-16 mt-6"
          />
        </div>
        <div class="uploaded-photo">
          <div class="upload-name flex flex-row">
            <div class="image">
              <AppIcon :name="$t('ComponentXeFileUpload.UploadPhotoText').value">
                <IconImage />
              </AppIcon>
            </div>
            <div class="flex flex-col">
              <p>{{ file.name | maxLength(28) }}</p>
              <span v-if="!isFailedFileUpload" class="file-size">
                {{ (file.size / 1024 / 1024).toFixed(3) }} MB
              </span>
              <span v-else class="upload-error-msg">{{ uploadErrorMsg }}</span>
            </div>
          </div>
          <div class="icons">
            <button class="button-clear" @click="clearInput">
              <AppIcon v-if="!isFailedFileUpload">
                <IconTrashCan class="text-blue" />
              </AppIcon>
              <AppIcon
                v-else
                :name="$t('ComponentXeFileUpload.ButtonClearText').value"
                class="text-blue"
              >
                <IconClose />
              </AppIcon>
            </button>
          </div>
        </div>
      </AppCard>
    </div>
  </div>
</template>

<script>
import { IconImage, IconClose, IconTrashCan } from '@oen.web.vue2/icons'
import { useI18nStore } from '@galileo/stores'
import { ref, reactive, computed } from '@vue/composition-api'

import { AppIcon, AppCard } from '@oen.web.vue2/ui'
import { IconAddFile, IconDocumentPDF } from '@galileo/assets/icons/vue'

export default {
  name: 'XeSofUpload',
  components: {
    AppIcon,
    IconImage,
    IconClose,
    AppCard,
    IconTrashCan,
    IconAddFile,
    IconDocumentPDF,
  },
  props: {
    maxSize: {
      type: Number,
      default: null,
    },
    acceptedFileTypes: {
      type: Array,
      default: () => [],
    },
    rawContent: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const fileUploaded = ref(false)
    const isFailedFileUpload = ref(false)

    const onUploadFailedHandler = (errorMessage) => {
      isFailedFileUpload.value = true
      emit('validationError', errorMessage)
    }

    let file = reactive({
      name: null,
      size: null,
      type: null,
      data: null,
    })

    const clearInput = () => {
      file.name = null
      file.size = null
      file.type = null
      file.data = null
      fileUploaded.value = false
      isFailedFileUpload.value = false
      fileUploadInputElement.value.value = null
      emit('cleared')
    }

    const uploadErrorMsg = ref('')

    const previewThumbnailUrl = ref('')

    const showThumbnailOnSelect = (event) => {
      let file
      if (event.dataTransfer) {
        file = event.dataTransfer.files[0]
      } else {
        file = event.target.files[0]
      }
      if (!file) {
        return false
      }
      if (!props.acceptedFileTypes.includes(file.type) || file.type === 'application/pdf') {
        return false
      }
      const reader = new FileReader()
      reader.onload = function (e) {
        previewThumbnailUrl.value = e.target.result
      }
      reader.readAsDataURL(file)
    }

    const upload = async (event) => {
      let uploadedFile = {}
      previewThumbnailUrl.value = undefined
      if (event.dataTransfer) {
        uploadedFile = event.dataTransfer.files[0]
      } else {
        uploadedFile = event.target.files[0]
      }

      file.name = uploadedFile?.name
      file.type = uploadedFile?.type
      file.size = uploadedFile?.size

      //validate the file types
      if (props.acceptedFileTypes != null && props.acceptedFileTypes.length > 0) {
        let validType = props.acceptedFileTypes.includes(file.type)
        if (!validType) {
          uploadErrorMsg.value = $t('ComponentXeFileUpload.FileTypeInvalidError').value
          onUploadFailedHandler(
            $t('ComponentXeFileUpload.WrongFileTypeErrorText').value +
              ' ' +
              props.acceptedFileTypes.join(' ')
          )
          return
        }
      }

      //validate max size, comparing the size in KBs
      if (props.maxSize != null && props.maxSize * 1024 < file.size / 1024) {
        uploadErrorMsg.value = $t('ComponentXeFileUpload.FileSizeExceededError').value
        onUploadFailedHandler(
          $t('ComponentXeFileUpload.LargeFileErrorText').value + ' ' + props.maxSize + 'mb'
        )

        return
      }

      if (props.rawContent) {
        isFailedFileUpload.value = false
        blobToBase64(uploadedFile).then((base64) => {
          file.data = base64
          fileUploaded.value = true
          emit('uploaded', file)
        })
      } else {
        isFailedFileUpload.value = false
        fileUploaded.value = true
        emit('uploaded', uploadedFile)
      }
    }

    async function blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    }

    // Mobile button and dropdown

    const fileUploadInputElement = ref()
    const isMobileDevice = computed(() => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    })

    return {
      $t,
      fileUploaded,
      clearInput,
      upload,
      file,
      isFailedFileUpload,
      uploadErrorMsg,
      showThumbnailOnSelect,
      previewThumbnailUrl,
      isMobileDevice,
      fileUploadInputElement,
    }
  },
}
</script>

<style scoped>
.upload-successful,
.upload-failed {
  &.card.card--has-content {
    @apply mb-8;
  }
}

.upload-photo {
  @apply flex justify-between items-center box-border bg-gray-lightest rounded-lg shadow-ria-1;
  border: 1px dashed #dddddd;
}

.upload-photo p {
  @apply font-medium;
  line-height: 23px;
}
.drag-drop-text {
  @apply text-sm text-center tracking-normal;
  ::v-deep .action-text {
    @apply text-blue-text;
  }
}
.upload-button {
  @apply flex flex-col items-center justify-between gap-4 w-full py-6 text-gray-text cursor-pointer;
}
.upload-name {
  @apply flex flex-row items-start;
  svg {
    @apply mr-1;
  }
}
.button-clear {
  @apply mr-6 gap-4 flex;
}

.icons {
  @apply gap-4 flex;
}

.limit-size-text {
  @apply text-xs text-gray-dark;
}

::v-deep .card-content {
  @apply overflow-hidden p-0 m-0;
}

.thumbnail-image {
  @apply max-h-60 self-center items-center text-center justify-center content-center m-auto;
}

.uploaded-photo {
  @apply flex justify-between items-center pt-5 pr-0 pb-0 pl-5;
}
.file-size {
  @apply text-sm;
  color: rgba(179, 184, 194, 1);
}

.image {
  @apply flex;
}

.upload-error-msg {
  @apply text-xs text-red-text;
}

.upload-failed {
  @apply rounded-2xl;
  border: dashed 0.5px rgba(205, 61, 100, 1);
  @screen sm {
    @apply rounded-lg;
  }
}

.upload-photo-mobile {
  @apply mb-6 flex justify-between h-16 rounded-lg items-center box-border shadow-ria-1;
  border-color: #dddddd;
  border-width: 0.5px;
}

.upload-photo-mobile p {
  @apply font-semibold text-base;
}

.upload-button-mobile {
  @apply relative flex cursor-pointer px-6 w-full justify-between;
}

.file-preview {
  @apply flex justify-center;
}

.error-text-desktop {
  @apply text-red-text text-xs mt-1;
}
</style>
