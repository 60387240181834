<template>
  <span>
    <slot v-if="!isStringTruncated"></slot>
    <XeToolTip
      v-else
      activation="hover"
      side="top"
      :class="{ 'change-inner-text': !noResize, 'w-full': noResize }"
    >
      <template #wrappedElement>
        <slot class="truncated"></slot>
      </template>
      <template #tooltipContent>
        <h3 class="tooltip-name">{{ name }}</h3>
      </template>
    </XeToolTip>
  </span>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import XeToolTip from '@galileo/components/XeTooltip/XeTooltip'

export default {
  name: 'EllipsisWrapper',
  components: {
    XeToolTip,
  },
  props: {
    value: [String, Object],
    charactersLimit: {
      type: Number,
      required: false,
      default: 26,
    },
    noResize: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const name = computed(() => {
      return props.value
    })

    const isStringTruncated = ref(props.value?.length > props.charactersLimit)

    const truncateRecipientName = (str, num) => {
      if (str.length > num) {
        isStringTruncated.value = true
        return str.slice(0, num) + '...'
      } else {
        return str
      }
    }

    return {
      name,
      isStringTruncated,
      truncateRecipientName,
    }
  },
}
</script>

<style>
.change-inner-text > :first-child {
  display: inline-block;
  overflow: hidden;
  position: relative;
  min-width: 150px;
  max-width: 250px;
  width: 13vw;
  margin-bottom: -5px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.change-inner-text {
  word-break: break-all;
}
</style>
