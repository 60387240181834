<template>
  <InformationAppModal
    class="upload-document-modal"
    :value="value"
    :icon="modalStage === 'errorUploading' ? ICON_TYPE.CROSS_MARK : ''"
    :shouldDisplayCloseButton="modalStage !== 'errorUploading'"
    @close="closeModal"
  >
    <template #header>
      <div class="flex flex-row items-center" v-if="modalStage !== 'errorUploading'">
        {{
          $t(`UploadDocumentModal.${props.documentType.documentDescriptionId}.Title`, {
            fallbackKey: `UploadDocumentModal.${props.documentType.documentType}.Title`,
          }).value
        }}
      </div>
    </template>

    <AppSpinnerBig inline :loading="modalStage === 'uploading'" />

    <p
      v-if="modalStage === 'beforeSelection'"
      v-html="
        $t(`UploadDocumentModal.${props.documentType.documentDescriptionId}.Subtitle`, {
          fallbackKey: `UploadDocumentModal.${props.documentType.documentType}.Subtitle`,
        }).value
      "
      class="leading-5 mb-4 text-left"
    />

    <ul
      class="tips-list"
      v-if="modalStage === 'beforeSelection'"
      v-html="
        $t(`UploadDocumentModal.${props.documentType.documentDescriptionId}.Tips`, {
          fallbackKey: `UploadDocumentModal.${props.documentType.documentType}.Tips`,
        }).value
      "
    />

    <ul
      v-if="(modalStage === 'documentSelected') | (modalStage === 'errorSelecting')"
      v-html="$t('UploadDocumentModal.DateRequirement').value"
    />
    <!-- $t('UploadDocumentModal.DateRequirement').value -->
    <p
      v-if="(modalStage === 'documentSelected') | (modalStage === 'errorSelecting')"
      class="check-document-warning bg-gray-bg p-2 rounded mb-4 leading-5s"
    >
      ⚠️ {{ $t('UploadDocumentModal.CheckDocumentWarning').value }}
    </p>

    <XeSofUpload
      v-if="modalStage !== 'uploading' && modalStage !== 'errorUploading'"
      ref="xeFileUploadRef"
      :max-size="5"
      :acceptedFileTypes="['image/png', 'image/jpeg', 'application/pdf']"
      @uploaded="fileSelectedCallback"
      @drop.prevent="fileUploaded"
      @cleared="fileCleared"
      @validationError="uploadHasErrors"
    />

    <template #footer>
      <AppButton v-if="modalStage === 'documentSelected'" class="my-5" @click="saveDocument">
        {{ $t('UploadDocumentModal.SubmitDocumentButtonText').value }}
      </AppButton>

      <AppButton
        v-if="modalStage === 'documentSelected'"
        theme="secondary"
        @click="uploadFileAgain"
      >
        {{ $t('UploadDocumentModal.UploadFileAgain').value }}
      </AppButton>
    </template>

    <template v-if="modalStage === 'errorUploading'">
      <h2 class="text-2xl font-semibold">{{ $t('UploadDocumentModal.UploadFaildTitle').value }}</h2>
      <p>{{ $t('UploadDocumentModal.UploadFaildText').value }}</p>
      <AppButton class="my-5" @click="restart">
        {{ $t('UploadDocumentModal.UploadFaildTryAgainButton').value }}
      </AppButton>
      <AppButton theme="secondary" @click="showContactUs">
        {{ $t('UploadDocumentModal.UploadFaildContactUsButton').value }}
      </AppButton>
    </template>
  </InformationAppModal>
</template>

<script>
import XeSofUpload from '@galileo/components/XeFileUpload/XeSofUpload.vue'
import XeFileUpload from '@galileo/components/XeFileUpload/XeFileUpload.vue'
import InformationAppModal, {
  ICON_TYPE,
} from '@galileo/components/InformationAppModal/InformationAppModal'
import { ref } from '@vue/composition-api'
import { AppButton, AppSpinnerBig, AppIcon, useAlert } from '@oen.web.vue2/ui'
import { IconInformation } from '@oen.web.vue2/icons'
import { useAppStore, useStpStore, useI18nStore } from '@galileo/stores'
import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'

// In these stages, selecting means picking a file to upload and uploading means sending that file to an endpoint
const UPLOAD_DOCUMENT_STAGE = {
  BEFORE_SELECTION: 'beforeSelection',
  DOCUMENT_SELECTED: 'documentSelected',
  ERROR_SELECTING: 'errorSelecting',
  UPLOADING: 'uploading',
  ERROR_UPLOADING: 'errorUploading',
}

export default {
  name: 'UploadDocumentModal',
  components: {
    InformationAppModal,
    XeSofUpload,
    AppButton,
    XeFileUpload,
    AppSpinnerBig,
    ICON_TYPE,
    IconInformation,
    AppIcon,
    useAlert,
  },
  props: {
    documentType: {
      type: Object | null,
      default: { documentType: 'idBankStatement', documentDescriptionId: 'idBankStatementSoleGB' },
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['input', 'successfulUpload'],
  setup(props, { emit }) {
    const file = ref(null)
    const xeFileUploadRef = ref()
    const { $t } = useI18nStore()
    const stpStore = useStpStore()
    const appStore = useAppStore()

    const capitalizeFirstLetter = (string) => {
      if (!string) return ''
      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const { add: showSnackAlert } = useAlert()

    const fileSelectedCallback = (fileEvent) => {
      file.value = fileEvent
      modalStage.value = UPLOAD_DOCUMENT_STAGE.DOCUMENT_SELECTED
    }

    const modalStage = ref(UPLOAD_DOCUMENT_STAGE.BEFORE_SELECTION)

    const fileCleared = () => {
      file.value = null
      modalStage.value = UPLOAD_DOCUMENT_STAGE.BEFORE_SELECTION
    }

    const uploadFileAgain = () => {
      xeFileUploadRef.value.clearInput()
      xeFileUploadRef.value.fileUploadInputElement.click()
      restart()
    }

    const uploadHasErrors = (errors) => {
      modalStage.value = UPLOAD_DOCUMENT_STAGE.ERROR_SELECTING
    }

    const saveDocument = async () => {
      modalStage.value = UPLOAD_DOCUMENT_STAGE.UPLOADING
      try {
        const uploaded = await stpStore.uploadRequiredDocument({
          documentType: props.documentType.documentType,
          file: file.value,
        })
        if (uploaded) {
          showSnackAlert($t('UploadDocumentModal.SuccessfullyUploadedToastMessage').value)
          emit('successfullUpload')
          closeModal()
        } else {
          modalStage.value = UPLOAD_DOCUMENT_STAGE.ERROR_UPLOADING
        }
      } catch (ex) {
        useAppStore().logException('Error during uploading file', ex)
        modalStage.value = UPLOAD_DOCUMENT_STAGE.ERROR_UPLOADING
      }
    }

    const showContactUs = () => {
      appStore.openHelpDesk({
        loadingRef: ref(false),
        constName: HelpDeskProperties.CONTACT_DETAILS,
        article: HelpDesk[HelpDeskProperties.CONTACT_DETAILS],
      })
    }

    const closeModal = () => {
      modalStage.value = UPLOAD_DOCUMENT_STAGE.BEFORE_SELECTION
      emit('input', false)
    }
    const restart = () => {
      modalStage.value = UPLOAD_DOCUMENT_STAGE.BEFORE_SELECTION
    }

    return {
      file,
      fileCleared,
      fileSelectedCallback,
      uploadHasErrors,
      modalStage,
      saveDocument,
      uploadFileAgain,
      ICON_TYPE,
      closeModal,
      restart,
      xeFileUploadRef,
      $t,
      props,
      capitalizeFirstLetter,
      showContactUs,
    }
  },
}
</script>
<style scoped>
.upload-document-modal {
  ::v-deep .card-content-block {
    @apply m-0;
  }
  ::v-deep .modal-body p {
    @apply text-sm;
  }
  ::v-deep .card-header-title {
    @apply overflow-visible;
  }
  ::v-deep .card-header-title > * {
    @apply overflow-visible;
  }
  ul {
    @apply text-left list-disc text-secondary-text text-sm list-inside mb-5;
  }
}
</style>
