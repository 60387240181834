<template>
  <div class="upload-photo">
    <template v-if="!fileUploaded">
      <input v-show="false" id="fileUploadInput" type="file" @change="upload" />
      <label for="fileUploadInput" class="upload-button">
        <p>{{ $t('ComponentXeFileUpload.UploadPhotoText').value }}</p>
        <AppIcon :name="$t('ComponentXeFileUpload.UploadPhotoText').value">
          <IconImage />
        </AppIcon>
      </label>
    </template>
    <template v-else>
      <div class="upload-name">
        <AppIcon :name="$t('ComponentXeFileUpload.UploadPhotoText').value">
          <IconImage />
        </AppIcon>
        <p>{{ file.name | maxLength(28) }}</p>
      </div>
      <button v-on:click="clearInput" class="button-clear">
        <AppIcon :name="$t('ComponentXeFileUpload.ButtonClearText').value">
          <IconClose />
        </AppIcon>
      </button>
    </template>
  </div>
</template>

<script>
import { IconImage, IconClose } from '@oen.web.vue2/icons'
import { useI18nStore } from '@galileo/stores'
import { ref, reactive } from '@vue/composition-api'

import { AppIcon, AppInputWrapper, AppButton } from '@oen.web.vue2/ui'

export default {
  name: 'XeFileUpload',
  components: {
    AppInputWrapper,
    AppIcon,
    IconImage,
    IconClose,
    AppButton,
  },
  props: {
    maxSize: {
      type: Number,
      default: null,
    },
    accept: {
      type: Array,
      default: null,
    },
    rawContent: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const fileUploaded = ref(false)

    let file = reactive({
      name: null,
      size: null,
      type: null,
      data: null,
    })

    const clearInput = () => {
      file.name = null
      file.size = null
      file.type = null
      file.data = null
      fileUploaded.value = false
      emit('cleared')
    }

    const upload = async (event) => {
      let uploadedFile = event.target.files[0]
      file.name = uploadedFile.name
      file.type = uploadedFile.type
      file.size = uploadedFile.size

      //validate max size, comparing the size in KBs
      if (props.maxSize != null && props.maxSize * 1024 < file.size / 1024) {
        emit(
          'validationError',
          $t('ComponentXeFileUpload.LargeFileErrorText').value + ' ' + props.maxSize + 'mb'
        )
        clearInput()
        return
      }

      //validate the file types
      if (props.accept != null && props.accept.length > 0) {
        let validType = props.accept.includes(file.type)
        if (!validType) {
          //show file extensions instead of "tecnical" files type.
          let acceptedExtensions = props.accept.map((type) => {
            let fileExtension = type.split('/')[1]
            return `.${fileExtension}`
          })
          emit(
            'validationError',
            $t('ComponentXeFileUpload.WrongFileTypeErrorText').value +
              ' ' +
              acceptedExtensions.join(' ')
          )
          clearInput()
          return
        }
      }
      if (props.rawContent) {
        // let response = await blobToBase64(uploadedFile)
        blobToBase64(uploadedFile).then((base64) => {
          file.data = base64
          fileUploaded.value = true
          emit('uploaded', file)
        })
      } else {
        fileUploaded.value = true
        emit('uploaded', uploadedFile)
      }
    }

    async function blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    }

    return {
      $t,
      fileUploaded,
      clearInput,
      upload,
      file,
    }
  },
}
</script>

<style scoped>
.upload-photo {
  @apply mb-6 flex justify-between h-16 rounded-lg items-center box-border shadow-ria-1;
  border-color: #dddddd;
  border-width: 0.5px;
}

.upload-photo p {
  @apply font-medium text-base;
}
.upload-button {
  @apply px-6;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.upload-name {
  @apply ml-6 flex items-center;

  svg {
    @apply mr-1;
  }
}
.button-clear {
  @apply mr-6 flex;
}
</style>
