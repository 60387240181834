<template>
  <div class="upload-documents">
    <AppSpinnerBig :loading="pageLoading" />
    <AppList class="xe-document-list" v-if="hasDocumentsToUpload">
      <div class="page-header">
        <h1 class="page-title">{{ $t('PageDocumentUpload.PageTitle').value }}</h1>
        <p class="page-caption">{{ $t('PageDocumentUpload.PageCaption').value }}</p>
      </div>

      <EllipsisWrapper
        v-for="document in requiredDocuments"
        :key="document.id"
        :value="document.documentType"
        no-resize
      >
        <AppListItem
          class="upload-document-item"
          :analitycs-name="'documentType' + '-' + document.documentType"
        >
          <template #figure>
            <AppFigure class="xe-figure" theme="gray">
              <img src="@galileo/assets/images/document.svg" alt="document icon" />
            </AppFigure>
          </template>

          <template #left>
            <AppListItemTitle>
              <span>{{
                $t(`UploadDocumentModal.${document.documentDescriptionId}.Title`, {
                  fallbackKey: `UploadDocumentModal.${document.documentType}.Title`,
                }).value
              }}</span>
            </AppListItemTitle>
          </template>

          <template #action>
            <div class="upload-buttons">
              <AppButton
                class="choose-file-button"
                theme="primary"
                analytics-name="activity-details"
                @click="openUploadDocumentModal(document)"
              >
                {{ $t('PageDocumentUpload.ButtonUploadText').value }}
              </AppButton>
            </div>
          </template>
        </AppListItem>
      </EllipsisWrapper>
    </AppList>
    <AppCard v-else-if="!pageLoading" class="no-background">
      <template #header>
        <AppCardHeader class="no-docs-header">
          <AppFigure class="xe-figure" theme="gray">
            <img src="@galileo/assets/images/document.svg" alt="document icon" />
          </AppFigure>
        </AppCardHeader>
      </template>
      <h1 class="no-docs-title">
        {{ $t('PageDocumentUpload.NoDocumentsNeededTitle').value }}
      </h1>
      <p class="no-docs-text">
        {{ $t('PageDocumentUpload.NoDocumentsNeededText').value }}
      </p>
      <AppButton class="mt-8" @click="backToHomeHandler"
        >{{ $t('PageDocumentUpload.BackToHomeButtonText').value }}
      </AppButton>
    </AppCard>

    <UploadDocumentModal
      v-if="selectedDocument"
      v-model="isUploadDocumentModalOpen"
      :documentType="selectedDocument"
      @successfullUpload="recallRequiredDocuments"
    />
  </div>
</template>

<script>
import {
  AppButton,
  AppFigure,
  AppList,
  AppListItem,
  AppListItemCaption,
  AppListItemTitle,
  AppCard,
  AppCardHeader,
} from '@oen.web.vue2/ui'

import { ref, computed, onBeforeMount } from '@vue/composition-api'
import { useI18n, useIntlDateTimeFormat } from 'vue-composable'
import { defineComponent } from '@vue/composition-api'
import UploadDocumentModal from '@galileo/components/UploadDocumentModal/UploadDocumentModal'
import { AppSpinnerBig } from '@oen.web.vue2/ui'
import EllipsisWrapper from '@galileo/components/Views/Recipient/EllipsisWrapper.vue'
import { IconCheckmarkBig } from '@oen.web.vue2/icons'

import { useRouter } from '@galileo/composables/useRouter'

import { useI18nStore, useStpStore } from '@galileo/stores'

export default defineComponent({
  components: {
    AppButton,
    AppFigure,
    AppList,
    AppListItem,
    AppListItemCaption,
    AppListItemTitle,
    EllipsisWrapper,
    UploadDocumentModal,
    AppCard,
    AppCardHeader,
    IconCheckmarkBig,
    AppSpinnerBig,
  },
  setup() {
    const router = useRouter()
    const { $t } = useI18nStore()
    const stpStore = useStpStore()
    const requiredDocuments = computed(() => {
      return stpStore.requiredDocuments
    })

    const hasDocumentsToUpload = computed(() => {
      return stpStore.hasDocumentsToUpload
    })

    const selectedDocument = ref(null)

    const isUploadDocumentModalOpen = ref(false)

    const openUploadDocumentModal = (document) => {
      selectedDocument.value = document
      isUploadDocumentModalOpen.value = true
    }
    const recallRequiredDocuments = async () => {
      await stpStore.getRequiredDocumentsList()
    }

    const pageLoading = ref(false)

    onBeforeMount(async () => {
      pageLoading.value = true
      await stpStore.getRequiredDocumentsList()
      pageLoading.value = false
    })

    const backToHomeHandler = () => {
      router.replace('/activity')
    }
    return {
      $t,
      requiredDocuments,
      openUploadDocumentModal,
      selectedDocument,
      isUploadDocumentModalOpen,
      recallRequiredDocuments,
      hasDocumentsToUpload,
      pageLoading,
      backToHomeHandler,
    }
  },
})
</script>

<style scoped>
.upload-documents {
  @apply flex flex-col gap-12 px-4;
  max-width: 640px;
  margin: auto;
}
.page-header {
  @apply mt-12;
}
.page-title {
  @apply text-3xl font-semibold;
}
.page-caption {
  @apply text-sm font-normal;
  @apply my-4 text-tertiary-text;
}
.xe-document-list {
  @apply flex flex-col gap-6;
}
.upload-document-item {
  @apply p-3 rounded-xl;
  background-color: white;
  ::v-deep .list-item-content {
    @apply py-0;
  }
}

::v-deep .list-item-content-flex-container .list-item-left {
  @apply truncate;
}
::v-deep .list-item-content-flex-container .list-item-left .list-item-title {
  @apply truncate;
}
::v-deep .upload-document-item .figure {
  @apply h-10 w-10;
}
::v-deep .figure--gray {
  border-radius: 50%;
}
.no-docs-header {
  @apply pb-8 pt-10;
}
.no-docs-title {
  @apply text-lg mb-5 text-center tracking-tight font-semibold;
}
.no-docs-text {
  @apply text-secondary-text text-center tracking-tight  text-sm;
}

.card {
  @apply mt-0;
}
.no-background {
  background: rgba(0, 0, 0, 0);
  ::v-deep .card-header-title {
    height: fit-content;
  }
}
</style>
