var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('InformationAppModal',{staticClass:"upload-document-modal",attrs:{"value":_vm.value,"icon":_vm.modalStage === 'errorUploading' ? _vm.ICON_TYPE.CROSS_MARK : '',"shouldDisplayCloseButton":_vm.modalStage !== 'errorUploading'},on:{"close":_vm.closeModal},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.modalStage !== 'errorUploading')?_c('div',{staticClass:"flex flex-row items-center"},[_vm._v(" "+_vm._s(_vm.$t(("UploadDocumentModal." + (_vm.props.documentType.documentDescriptionId) + ".Title"), { fallbackKey: ("UploadDocumentModal." + (_vm.props.documentType.documentType) + ".Title"), }).value)+" ")]):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [(_vm.modalStage === 'documentSelected')?_c('AppButton',{staticClass:"my-5",on:{"click":_vm.saveDocument}},[_vm._v(" "+_vm._s(_vm.$t('UploadDocumentModal.SubmitDocumentButtonText').value)+" ")]):_vm._e(),(_vm.modalStage === 'documentSelected')?_c('AppButton',{attrs:{"theme":"secondary"},on:{"click":_vm.uploadFileAgain}},[_vm._v(" "+_vm._s(_vm.$t('UploadDocumentModal.UploadFileAgain').value)+" ")]):_vm._e()]},proxy:true}])},[_c('AppSpinnerBig',{attrs:{"inline":"","loading":_vm.modalStage === 'uploading'}}),(_vm.modalStage === 'beforeSelection')?_c('p',{staticClass:"leading-5 mb-4 text-left",domProps:{"innerHTML":_vm._s(
      _vm.$t(("UploadDocumentModal." + (_vm.props.documentType.documentDescriptionId) + ".Subtitle"), {
        fallbackKey: ("UploadDocumentModal." + (_vm.props.documentType.documentType) + ".Subtitle"),
      }).value
    )}}):_vm._e(),(_vm.modalStage === 'beforeSelection')?_c('ul',{staticClass:"tips-list",domProps:{"innerHTML":_vm._s(
      _vm.$t(("UploadDocumentModal." + (_vm.props.documentType.documentDescriptionId) + ".Tips"), {
        fallbackKey: ("UploadDocumentModal." + (_vm.props.documentType.documentType) + ".Tips"),
      }).value
    )}}):_vm._e(),((_vm.modalStage === 'documentSelected') | (_vm.modalStage === 'errorSelecting'))?_c('ul',{domProps:{"innerHTML":_vm._s(_vm.$t('UploadDocumentModal.DateRequirement').value)}}):_vm._e(),((_vm.modalStage === 'documentSelected') | (_vm.modalStage === 'errorSelecting'))?_c('p',{staticClass:"check-document-warning bg-gray-bg p-2 rounded mb-4 leading-5s"},[_vm._v(" ⚠️ "+_vm._s(_vm.$t('UploadDocumentModal.CheckDocumentWarning').value)+" ")]):_vm._e(),(_vm.modalStage !== 'uploading' && _vm.modalStage !== 'errorUploading')?_c('XeSofUpload',{ref:"xeFileUploadRef",attrs:{"max-size":5,"acceptedFileTypes":['image/png', 'image/jpeg', 'application/pdf']},on:{"uploaded":_vm.fileSelectedCallback,"drop":function($event){$event.preventDefault();return _vm.fileUploaded.apply(null, arguments)},"cleared":_vm.fileCleared,"validationError":_vm.uploadHasErrors}}):_vm._e(),(_vm.modalStage === 'errorUploading')?[_c('h2',{staticClass:"text-2xl font-semibold"},[_vm._v(_vm._s(_vm.$t('UploadDocumentModal.UploadFaildTitle').value))]),_c('p',[_vm._v(_vm._s(_vm.$t('UploadDocumentModal.UploadFaildText').value))]),_c('AppButton',{staticClass:"my-5",on:{"click":_vm.restart}},[_vm._v(" "+_vm._s(_vm.$t('UploadDocumentModal.UploadFaildTryAgainButton').value)+" ")]),_c('AppButton',{attrs:{"theme":"secondary"},on:{"click":_vm.showContactUs}},[_vm._v(" "+_vm._s(_vm.$t('UploadDocumentModal.UploadFaildContactUsButton').value)+" ")])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }